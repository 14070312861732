
import React, { useEffect } from 'react';

const Sitemap = () => {
  useEffect(() => {
    const sitemapXML = `<?xml version="1.0" encoding="UTF-8"?>
		<!--	created with www.mysitemapgenerator.com	-->
		<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
<url>
	<loc>https://www.placestovisitindia.com/</loc>
	<lastmod>2024-12-06T19:51:53+01:00</lastmod>
	<priority>0.6</priority>
</url>
<url>
	<loc>https://www.placestovisitindia.com/trekking</loc>
	<lastmod>2024-12-06T19:51:53+01:00</lastmod>
	<priority>1.0</priority>
</url>
<url>
	<loc>https://www.placestovisitindia.com/heritage</loc>
	<lastmod>2024-12-06T19:51:53+01:00</lastmod>
	<priority>1.0</priority>
</url>
<url>
	<loc>https://www.placestovisitindia.com/blogs</loc>
	<lastmod>2024-12-06T19:51:53+01:00</lastmod>
	<priority>1.0</priority>
</url>
<url>
	<loc>https://www.placestovisitindia.com/AboutUs</loc>
	<lastmod>2024-12-06T19:51:53+01:00</lastmod>
	<priority>1.0</priority>
</url>
<url>
	<loc>https://www.placestovisitindia.com/ContactUs</loc>
	<lastmod>2024-12-06T19:51:53+01:00</lastmod>
	<priority>1.0</priority>
</url>
<url>
	<loc>https://www.placestovisitindia.com/privacypolicy</loc>
	<lastmod>2024-12-06T19:51:53+01:00</lastmod>
	<priority>1.0</priority>
</url>
<url>
	<loc>https://www.placestovisitindia.com/copyrightpolicy</loc>
	<lastmod>2024-12-06T19:51:53+01:00</lastmod>
	<priority>1.0</priority>
</url>
<url>
	<loc>https://www.placestovisitindia.com/sitemap.xml</loc>
	<lastmod>2024-12-06T19:51:53+01:00</lastmod>
	<priority>1.0</priority>
</url>
</urlset>`;

    

    const blob = new Blob([sitemapXML], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    window.location.href = url;

    return () => URL.revokeObjectURL(url);
  }, []);

  return null; 
};

export default Sitemap;
